<template>
  <div id="Paste">
    <h2>如图所示：</h2>
    <Image-Zoom
      :img="`/images/my/real-name-authentication-hint-paste.png`"
      :thumbnail="`/images/my/real-name-authentication-hint-paste.png`"
    />
    <div class="text">
      <h2>粘贴的操作如下：</h2>
      <p>
        <span>【输入一个数字】</span>
        ->
        <span>【点击空白框】</span>
        ->
        <span>【点击小绿点】</span>
        ->
        <span>【点击粘贴】</span>
        ->
        <span>【删除开头输入的数字】</span>
      </p>
    </div>
  </div>
</template>

<script>
import ImageZoom from "@/components/Image-Zoom";
export default {
  components: {
    ImageZoom,
  },
};
</script>

<style lang="less" scoped>
#Paste {
  > .text {
    margin-top: 1em;

    > p > span {
      display: inline-block;
    }
  }
}
</style>
